import React, { useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function App() {
  // State to store the selected video URL
  const [videoSrc, setVideoSrc] = useState('');
  const [loading, setLoading] = useState(false);

  // List of video options with your provided links
  const videoOptions = [
    { label: 'Select a video', value: '' },
    { label: 'Sample Video 1', value: 'https://amplify.akeefkhan.com/Videos/big_buck_bunny.mp4' },
    { label: 'Sample Video 2', value: 'https://amplify.akeefkhan.com/Videos/file_example_MP4_480_1_5MG.mp4' },
  ];

  // Function to update the video source when the dropdown changes
  const handleVideoChange = (event) => {
    setVideoSrc(event.target.value);
    setLoading(true); // Set loading to true when a video is selected
  };

  // Function to handle previous video
  const handlePreviousVideo = () => {
    const currentIndex = videoOptions.findIndex(option => option.value === videoSrc);
    if (currentIndex > 0) {
      setVideoSrc(videoOptions[currentIndex - 1].value);
      setLoading(true);
    }
  };

  // Function to handle next video
  const handleNextVideo = () => {
    const currentIndex = videoOptions.findIndex(option => option.value === videoSrc);
    if (currentIndex < videoOptions.length - 1) {
      setVideoSrc(videoOptions[currentIndex + 1].value);
      setLoading(true);
    }
  };

  return (
    <div className="App">
      <h1>
        <FontAwesomeIcon icon={faVideo} /> Video Player App
      </h1>
      <h2>Select a video from the dropdown to play</h2>
      {/* Dropdown to select the video */}
      <select onChange={handleVideoChange}>
        {videoOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {loading && <p>Loading video...</p>}

      {/* Video container with next/previous buttons */}
      {videoSrc && (
        <div className="video-container">
          <button className="control-button prev" onClick={handlePreviousVideo}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <video
            className="video-player"
            controls
            onLoadStart={() => setLoading(true)}
            onLoadedData={() => setLoading(false)}
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <button className="control-button next" onClick={handleNextVideo}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
